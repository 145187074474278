import styles from "./Portal.module.scss";
import { Image, Spin, Typography } from "antd";
import { MoreVert } from "@mui/icons-material";
import { FeedTblRow } from "shared/types/assetExporter";
import { Tags } from "./Tags";
import { usePortalExportContext } from "./PortalExportContext";

export const PreviewCard = ({
  feedData,
  isLoadingNextImage,
  setIsLoadingNextImage,
}: {
  feedData: FeedTblRow[];
  isLoadingNextImage: boolean;
  setIsLoadingNextImage: (value: boolean) => void;
}) => {
  const { formValues } = usePortalExportContext();

  // Mock data until real data is connected
  const isLoading = isLoadingNextImage;
  const thumbnailURL = `data:image/svg+xml;base64,${btoa(
    '<svg xmlns="http://www.w3.org/2000/svg" width="300" height="200" viewBox="0 0 300 200"><rect width="300" height="200" fill="#ddd"/><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="sans-serif" font-size="24" fill="#333">Preview Image</text></svg>',
  )}`;
  const rowName = "Row Name";
  const dimensions = "100 x 100";
  const currentRow = feedData[0];

  return (
    <div className={styles.previewCard}>
      <Spin spinning={isLoading} wrapperClassName={styles.spinWrapper}>
        <div className={styles.previewImageWrapper}>
          <Image
            onLoad={() => setIsLoadingNextImage(false)}
            onError={() => setIsLoadingNextImage(false)}
            preview={false}
            src={thumbnailURL}
          />
        </div>

        <div className={styles.metadataContainer}>
          <div>
            <Typography.Text className={styles.rowNameTitle}>
              {rowName}
            </Typography.Text>
            <Typography.Text
              type="secondary"
              className={styles.dimensionsTitle}
            >
              {dimensions}
            </Typography.Text>
          </div>
          <div className={styles.fieldsContainer}>
            <Tags
              currentRow={currentRow as FeedTblRow}
              feedColumns={formValues.feedColumns}
            />
            <MoreVert className={styles.moreOutlinedIcon} />
          </div>
        </div>
      </Spin>
    </div>
  );
};
