import { Collapse, Table } from "antd";
import styles from "./PrintOptionRateTable.module.scss";
import {
  PrintOptionItem,
  PrintMode,
  PrintOptionsRangeQuantity,
} from "shared/types/printOptions";
import { isObject } from "lodash";

const columns = [
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Simplex",
    dataIndex: "simplex",
    key: "simplex",
  },
  {
    title: "Duplex",
    dataIndex: "duplex",
    key: "duplex",
  },
];

export const PrintOptionRateTable = ({
  name,
  printOption,
}: {
  name?: string;
  printOption: PrintOptionItem;
}) => {
  const filteredColumns = columns.filter(
    ({ key }) =>
      printOption.printMode.includes(key.toUpperCase() as PrintMode) ||
      key === "quantity",
  );

  const getQuantity = (quantity: number | PrintOptionsRangeQuantity) => {
    if (isObject(quantity)) {
      return quantity.max
        ? `${quantity.min} - ${quantity.max}`
        : `${quantity.min}+`;
    }
    return quantity;
  };

  const dataSource = printOption.costPerCopy.map(copy => {
    const { quantity, cost } = copy;
    const { SIMPLEX: simplex, DUPLEX: duplex } = cost;

    const formattedQuantity = getQuantity(quantity);

    const formattedSimplex = simplex ? `$${simplex.toFixed(2)}` : "-";

    const formattedDuplex = duplex ? `$${duplex.toFixed(2)}` : "-";

    return {
      quantity: formattedQuantity,
      simplex: formattedSimplex,
      duplex: formattedDuplex,
    };
  });

  return (
    <Collapse className={styles.collapsibleTable} defaultActiveKey="table">
      <Collapse.Panel
        header={name ?? "Pages and Prices (Bulk Pack)"}
        key="table"
      >
        <Table
          columns={filteredColumns}
          dataSource={dataSource}
          pagination={false}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
