import styles from "./Portal.module.scss";
import { FeedTblRow } from "shared/types/assetExporter";

const Tag = ({ label }: { label: string }) => {
  return <div className={styles.tag}>{label}</div>;
};

export const Tags = ({
  currentRow,
  feedColumns,
}: {
  currentRow: FeedTblRow;
  feedColumns: string[];
}) => (
  <>
    {(feedColumns as (keyof FeedTblRow)[])
      .filter(column => !!currentRow[column])
      .map(column => {
        const value = currentRow[column];
        if (!value || typeof value !== "string") return null;
        return <Tag key={column} label={value} />;
      })}
  </>
);
