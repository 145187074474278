import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Table, TableColumnProps } from "antd";
import {
  PrintOptionsCopy,
  PrintModeOption,
  PrintOptionCopyType,
  PrintMode,
} from "shared/types/printOptions";
import uuid from "uuid";
import { MinMaxInput } from "./MinMaxInput";
import styles from "./PrintOptionsCostFieldTable.module.scss";
import { isObject } from "lodash";

export const PrintOptionsCostFieldTable = () => {
  const getThreshold = (
    index: number,
    costs: PrintOptionsCopy<PrintOptionCopyType>[],
  ) => {
    const previousItem = costs[index - 1];
    const nextItem = costs[index + 1];

    if (
      typeof previousItem?.quantity === "number" &&
      typeof nextItem?.quantity === "number"
    ) {
      return {
        previousMax: previousItem.quantity,
        nextMin: nextItem.quantity,
      };
    }
    if (
      typeof previousItem?.quantity !== "number" &&
      typeof nextItem?.quantity !== "number"
    ) {
      return {
        previousMax: previousItem?.quantity?.max,
        nextMin: nextItem?.quantity?.min,
      };
    }
    return {
      previousMax: undefined,
      nextMin: undefined,
    };
  };

  const getCostPerCopyColumns = <T extends PrintOptionCopyType>(
    printMode: PrintMode,
    dataSourceLength: number,
    remove: (index: number) => void,
  ) => {
    const columns: (TableColumnProps<PrintOptionsCopy<T>> & {
      hidden?: boolean;
    })[] = [
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        width: "50%",
        render: (_, record: PrintOptionsCopy<T>, index) => {
          return (
            <Form.Item
              noStyle
              shouldUpdate={(prev, current) =>
                prev.costPerCopy !== current.costPerCopy
              }
            >
              {({ getFieldValue }) => {
                const { previousMax, nextMin } = getThreshold(
                  index,
                  getFieldValue("costPerCopy"),
                );
                const min = previousMax ? previousMax + 1 : undefined;
                const max = nextMin ? nextMin - 1 : undefined;

                return (
                  <Form.Item name={[index, "quantity"]} noStyle>
                    {isObject(record.quantity) ? (
                      <MinMaxInput
                        fieldName={[index, "quantity"]}
                        value={record.quantity}
                        minThreshold={previousMax}
                        maxThreshold={nextMin}
                      />
                    ) : (
                      <InputNumber
                        style={{ width: "100%" }}
                        min={min}
                        max={max}
                      />
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: "Simplex Cost per Copy",
        dataIndex: ["cost", PrintModeOption.SIMPLEX],
        key: PrintModeOption.SIMPLEX,
        hidden: !printMode.includes(PrintModeOption.SIMPLEX),
        render: (_, __, index) => {
          return (
            <Form.Item
              name={[index, "cost", PrintModeOption.SIMPLEX]}
              className={styles.tableFormItem}
              rules={[{ required: true, message: "" }]}
              dependencies={["printMode"]}
            >
              <InputNumber className={styles.inputNumber} prefix="$" />
            </Form.Item>
          );
        },
      },
      {
        title: "Duplex Cost per Copy",
        dataIndex: ["cost", PrintModeOption.DUPLEX],
        key: PrintModeOption.DUPLEX,
        hidden: !printMode.includes(PrintModeOption.DUPLEX),
        render: (_, __, index) => {
          return (
            <Form.Item
              name={[index, "cost", PrintModeOption.DUPLEX]}
              className={styles.tableFormItem}
              rules={[{ required: true, message: "" }]}
              dependencies={["printMode"]}
            >
              <InputNumber className={styles.inputNumber} prefix="$" />
            </Form.Item>
          );
        },
      },
      {
        title: null,
        key: "action",
        render: (_, __, index) => {
          if (dataSourceLength === 1) return null;
          return <DeleteOutlined onClick={() => remove(index)} />;
        },
      },
    ];
    return columns.filter(column => !column.hidden);
  };
  return (
    <Form.Item shouldUpdate>
      {({ getFieldValue }) => {
        const printMode = getFieldValue("printMode");
        const costPerCopy = getFieldValue("costPerCopy");
        const lastMax = costPerCopy?.[costPerCopy.length - 1]?.quantity.max;
        const copySelection = getFieldValue("copySelection");
        return (
          <Form.List name="costPerCopy">
            {(fields, { add, remove }) => {
              const dataSource = fields.map((field, index) => ({
                ...costPerCopy[index],
                key: field.key,
              }));
              return (
                <Table
                  dataSource={dataSource}
                  columns={getCostPerCopyColumns(
                    printMode,
                    dataSource.length,
                    remove,
                  )}
                  pagination={false}
                  footer={() => (
                    <Button
                      type="link"
                      onClick={() =>
                        add({
                          id: uuid.v4(),
                          quantity:
                            copySelection === PrintOptionCopyType.RANGE
                              ? {
                                  min: lastMax + 1,
                                }
                              : costPerCopy[costPerCopy.length - 1].quantity +
                                1,
                          mode: printMode,
                        })
                      }
                    >
                      Add Row
                    </Button>
                  )}
                />
              );
            }}
          </Form.List>
        );
      }}
    </Form.Item>
  );
};
