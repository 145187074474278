import { Row, Typography, Form, Radio, Skeleton, Alert } from "antd";
import moment from "moment";
import { useCallback } from "react";
import { FedexShippingMethod } from "shared/types/marketingMaterials";
import styles from "./FedexShippingMethods.module.scss";
import { InfoCircleFilled } from "@ant-design/icons";

export const FedexShippingMethods = ({
  shippingMethods,
  isLoading,
}: {
  shippingMethods?: FedexShippingMethod[];
  isLoading?: boolean;
}) => {
  const getReadableDeliveryDate = useCallback((deliveryDate: string) => {
    const date = moment(deliveryDate);
    const formattedDate = date.format("dddd MMM D YYYY");
    return ` Receive it by ${formattedDate}`;
  }, []);

  if (!shippingMethods && !isLoading) return null;
  return (
    <Row className={styles.shippingRow}>
      {isLoading && <Skeleton active />}
      {!!shippingMethods && !isLoading && (
        <>
          <Typography.Title level={4}>Shipping Method</Typography.Title>
          <Form.Item name={["shippingData", "shippingMethod"]}>
            <Radio.Group>
              {shippingMethods.map(method => (
                <Radio key={method.serviceType} value={method}>
                  ${method.totalFedexCharge} {method.serviceName} -
                  {getReadableDeliveryDate(method.deliveryDate)}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Alert
            message="Delivery pricing and time are estimated. The final values will be provided by Communicorp upon the creation of the shipping label."
            type="info"
            showIcon
            icon={<InfoCircleFilled className={styles.infoIcon} />}
          />
        </>
      )}
    </Row>
  );
};
