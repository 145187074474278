import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styles from "./Navigation.module.scss";
import { useState } from "react";

interface NavigationProps {
  totalAssetCount: number;
  onNavigate: (index: number) => void;
  disabled?: boolean;
}

enum NavigationDirection {
  Previous = "previous",
  Next = "next",
}

export const Navigation = ({
  totalAssetCount,
  onNavigate,
  disabled,
}: NavigationProps) => {
  const [currentAssetIndex, setCurrentAssetIndex] = useState(0);

  const handleNavigate = (direction: NavigationDirection) => {
    setCurrentAssetIndex(prevIndex => {
      const newIndex =
        direction === NavigationDirection.Next ? prevIndex + 1 : prevIndex - 1;
      onNavigate(newIndex);
      return newIndex;
    });
  };

  const previousDisabled = currentAssetIndex === 0;
  const nextDisabled = currentAssetIndex === totalAssetCount - 1;
  const isLoading = totalAssetCount === 0;

  return (
    <div className={styles.container}>
      <div className={styles.buttonsContainer}>
        <Button
          disabled={previousDisabled || disabled}
          onClick={() => handleNavigate(NavigationDirection.Previous)}
          className={styles.button}
        >
          <ArrowLeftOutlined className={styles.icon} />
        </Button>
        <div className={styles.indexContainer}>
          {isLoading
            ? "Loading assets..."
            : `${currentAssetIndex + 1} / ${totalAssetCount} assets`}
        </div>
        <Button
          disabled={nextDisabled || disabled}
          onClick={() => handleNavigate(NavigationDirection.Next)}
          className={styles.button}
        >
          <ArrowRightOutlined className={styles.icon} />
        </Button>
      </div>
    </div>
  );
};
