import styles from "./Portal.module.scss";
import { Typography } from "antd";
import { useMemo, useState } from "react";
import { Navigation } from "../../shared/components/Preview/Navigation";
import { usePortalFeedData } from "./usePortalFeedData";
import { getTotalAssetCount } from "./utils/getTotalAssetCount";
import { PreviewCard } from "./PreviewCard";

export const RightPanel = () => {
  const [currentAssetIndex, setCurrentAssetIndex] = useState(0);
  const [isLoadingNextImage, setIsLoadingNextImage] = useState(true);
  const { result, prefetch: prefetchFeedData } =
    usePortalFeedData(currentAssetIndex);
  const { data: feedData, isLoading: isLoadingFeedData } = result;

  const handleNavigate = (index: number) => {
    prefetchFeedData();
    setCurrentAssetIndex(index);
    setIsLoadingNextImage(true);
  };

  const totalAssetCount = useMemo(() => {
    return getTotalAssetCount();
  }, []);

  return (
    <div className={styles.rightPanel}>
      <div className={styles.navigationContainer}>
        <Typography.Text strong className={styles.previewTitle}>
          Preview
        </Typography.Text>
        <Navigation
          totalAssetCount={totalAssetCount}
          onNavigate={handleNavigate}
          disabled={isLoadingFeedData}
        />
      </div>

      <div className={styles.rightPanelContent}>
        <PreviewCard
          feedData={feedData?.rows ?? []}
          isLoadingNextImage={isLoadingNextImage}
          setIsLoadingNextImage={setIsLoadingNextImage}
        />
      </div>
    </div>
  );
};
