import { Form, Select } from "antd";

import { PrintOptionRateTable } from "shared/components/salesEnablement/printOption/PrintOptionRateTable";
import { useFetchPrintOptions } from "shared/hooks/salesEnablement/useFetchPrintOptions";

export const PrintOptionsField = ({
  printOptionId,
}: {
  printOptionId?: string;
}) => {
  const { data: printOptions } = useFetchPrintOptions();
  const selectedPrintOption = printOptions?.find(
    option => option.id === printOptionId,
  );
  return (
    <>
      <Form.Item
        labelAlign="left"
        label="Print Options"
        name="printOptionId"
        rules={[
          {
            required: true,
            message: "Please select at least one print option.",
          },
        ]}
      >
        <Select
          options={printOptions?.map(option => ({
            label: option.name,
            value: option.id,
          }))}
        />
      </Form.Item>
      {selectedPrintOption && (
        <PrintOptionRateTable
          name="Price per copy"
          printOption={selectedPrintOption}
        />
      )}
    </>
  );
};
