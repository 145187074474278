import {
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  Row,
  Space,
  Typography,
} from "antd";
import { sum } from "lodash";
import { useMemo } from "react";
import { matchPath, useLocation, useParams } from "react-router-dom";
import { ROUTES as ARCHIVE_ROUTES } from "screens/designStudio/archive/constants";
import { useDeliveryMarketingMaterialByPrint } from "shared/hooks/adLibrary/marketingMaterials";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import {
  MarketingMaterialPrintDeliveryForm,
  MarketingMaterialPrintDeliveryInput,
} from "shared/types/marketingMaterials";
import { useEmailDeliveryData } from "../hooks/useEmailDeliveryData";
import { ROUTES } from "../utils/constants";
import styles from "./PrintCheckout.module.scss";
import { RateTable } from "./printCheckout/RateTable";
import { ShippingDataFields } from "./printCheckout/ShippingDataFields";

const printCheckoutFormId = "printCheckoutForm";

export const PrintCheckout = ({
  form,
}: {
  form: FormInstance<MarketingMaterialPrintDeliveryForm>;
}) => {
  const emailDeliveryData = useEmailDeliveryData();
  const navigateWithSearch = useNavigateWithSearch();
  const { mutate: deliveryMarketingMaterialByPrint } =
    useDeliveryMarketingMaterialByPrint();
  const location = useLocation();
  const { materialId } = useParams();
  const { archiveId } = useParams();

  const onClose = () => {
    if (archiveId) {
      navigateWithSearch(ARCHIVE_ROUTES.printArchive);
    } else {
      navigateWithSearch(ROUTES.print(materialId ?? ""));
    }
    form.resetFields(["shippingData"]);
  };

  const onSubmit = () => {
    const formData = form.getFieldsValue();
    const printDeliveryData: MarketingMaterialPrintDeliveryInput = {
      shippingData: formData.shippingData,
      deliveryMethod: "print",
      materials: emailDeliveryData.map(deliveryData => {
        const materialOrderData = formData.materials[deliveryData.material.id];
        const weight = deliveryData.material.printOption?.weight ?? 0;
        return {
          materialOrderData: {
            materialId: materialOrderData.id,
            copies: materialOrderData.copies,
            name: materialOrderData.name,
            printMode: materialOrderData.printMode,
            totalAmount: materialOrderData.totalAmount,
            totalWeight: materialOrderData.copies * weight,
          },
          deliveryMethod: "print",
          material: deliveryData.material,
          template: deliveryData.template,
          variables: deliveryData.variables,
          renderVariables: deliveryData.renderVariables,
        };
      }),
    };
    deliveryMarketingMaterialByPrint(printDeliveryData);
    onClose();
  };

  const isVisible = useMemo(() => {
    if (archiveId) {
      return !!matchPath(
        { path: ARCHIVE_ROUTES.printOrderDetails(archiveId) },
        location.pathname,
      );
    }
    return !!matchPath(
      { path: ROUTES.printCheckout(materialId) },
      location.pathname,
    );
  }, [archiveId, location.pathname, materialId]);

  const totalWeight = useMemo(() => {
    const formData: MarketingMaterialPrintDeliveryForm =
      form.getFieldsValue(true);
    const { materials } = formData;
    return sum(
      Object.values(materials).map(
        material => material?.printOption?.weight ?? 0,
      ),
    );
  }, [form]);

  return (
    <Drawer
      title="Order Prints"
      width="70%"
      visible={isVisible}
      onClose={onClose}
      className={styles.drawer}
      destroyOnClose
      footerStyle={{ textAlign: "right" }}
      bodyStyle={{ padding: 0 }}
      footer={
        <Space className={styles.footer}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            form={printCheckoutFormId}
            onClick={onSubmit}
          >
            Place Order
          </Button>
        </Space>
      }
    >
      <Row gutter={48} className={styles.row}>
        <Col span={12}>
          <Form.Item name="shippingData" noStyle>
            <ShippingDataFields
              disabled={!!archiveId}
              totalWeight={totalWeight}
            />
          </Form.Item>
        </Col>
        <Col span={12} className={styles.summaryCol}>
          <Form.Item shouldUpdate className={styles.summary}>
            {() => {
              const formData = form.getFieldsValue(true);
              const { materials, shippingData } = formData;
              if (!materials || !shippingData) {
                return null;
              }
              return (
                <>
                  <Typography.Title level={5}>Order Summary</Typography.Title>
                  <RateTable
                    materials={materials}
                    shippingMethod={shippingData.shippingMethod}
                    form={form}
                  />
                </>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </Drawer>
  );
};
