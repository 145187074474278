import API from "services";
import { useMutation } from "react-query";
import { queryClient } from "queryClient";
import { QUERY_KEYS } from "screens/adLibrary/marketingMaterials/utils/constants";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";

export const useUpdatePrintOption = () => {
  return useMutation({
    mutationFn: API.services.salesEnablement.updatePrintOption,
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEYS.printOptions]);
      queryClient.invalidateQueries(["sales-enablement-templates"]);
    },
    onSuccess: () => {
      successNotification({
        messageLabel: "The print option edits have been successfully saved.",
        size: "big",
      });
    },
    onError: () => {
      errorNotification({
        messageLabel: "Something went wrong updating the print option.",
        size: "big",
      });
    },
  });
};
