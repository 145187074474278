import API from "services";
import { useMutation } from "react-query";
import { queryClient } from "queryClient";
import { QUERY_KEYS } from "screens/adLibrary/marketingMaterials/utils/constants";
import { notification } from "antd";
import { PrintOptionItem } from "shared/types/printOptions";

export const useDeletePrintOption = () => {
  return useMutation({
    mutationFn: API.services.salesEnablement.deletePrintOption,
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEYS.printOptions]);
    },
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: "Print option deleted successfully.",
      });
    },
    onMutate: async id => {
      await queryClient.cancelQueries(QUERY_KEYS.printOptions);

      const previousPrintOptions = queryClient.getQueryData<PrintOptionItem[]>(
        QUERY_KEYS.printOptions,
      );

      if (!previousPrintOptions) {
        return;
      }

      const newPrintOptions = previousPrintOptions.filter(
        item => item.id !== id,
      );

      queryClient.setQueryData(QUERY_KEYS.printOptions, newPrintOptions);

      return previousPrintOptions;
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: "Something went wrong deleting the print option.",
      });
    },
  });
};
