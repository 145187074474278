import { format } from "date-fns";

export const toDateRange = (value?: { start?: Date; end?: Date }) => {
  const { start, end } = value ?? {};
  const dateFormat = "MM/dd/yy";
  if (!end) throw new Error("End date is required");

  const startPart = start ? (
    format(start, dateFormat)
  ) : (
    <span style={{ color: "gray" }}>Start Date</span>
  );
  const endPart = format(end, dateFormat);
  return (
    <div>
      {startPart} - {endPart}
    </div>
  );
};

export const hasValidDateRange = (value?: { start?: Date; end?: Date }) => {
  const { end } = value ?? {};
  return !!end;
};
