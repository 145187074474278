import { Drawer } from "antd";
import { matchPath, useParams } from "react-router-dom";
import { usePrintOption } from "shared/hooks/salesEnablement/usePrintOption";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { ROUTES } from "./constants";
import { PrintOptionsForm } from "./printOptionsDrawer/PrintOptionsForm";
import { DrawerFooter } from "./printOptionsDrawer/printOptionsForm/DrawerFooter";

const formId = "print-option-form";
export const PrintOptionsDrawer = () => {
  const { printOptionId } = useParams();
  const { printOption } = usePrintOption(printOptionId ?? "");
  const navigate = useNavigateWithSearch();

  const onClose = () => {
    navigate(ROUTES.home);
  };

  const isPrintOptionsDrawerVisible =
    !!matchPath({ path: ROUTES.create }, location.pathname) ||
    !!(
      printOptionId &&
      matchPath({ path: ROUTES.edit(printOptionId) }, location.pathname)
    );

  return (
    <Drawer
      title={`${printOptionId ? "Edit" : "New"} Print Option`}
      width={928}
      visible={isPrintOptionsDrawerVisible}
      onClose={onClose}
      closable={false}
      destroyOnClose
      footer={<DrawerFooter formId={formId} onCancel={onClose} />}
    >
      {isPrintOptionsDrawerVisible && (
        <PrintOptionsForm
          formId={formId}
          printOption={printOption}
          onClose={onClose}
        />
      )}
    </Drawer>
  );
};
