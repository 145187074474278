import { UploadFile } from "antd/lib/upload/interface";
import { StateKey } from "shared/constants/states";
import { IFile } from "shared/types/uploadAsset";

export enum TemplateStatus {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

export enum MaterialStatus {
  READY = "READY",
  DRAFT = "DRAFT",
  ERROR = "ERROR",
}

export type FileType = "indesign" | "html" | "pdf";
export type TemplateType = FileType;
export type Tag = {
  id: string;
  name: string;
};
export type EnrollmentCycle =
  | "prospecting"
  | "employeeEnrollment"
  | "productSpecific"
  | "servicing"
  | "brokerFacing"
  | "recruiting";

export type MaterialType =
  | "ad"
  | "article"
  | "brochure"
  | "contactCard"
  | "postcard"
  | "interestForm"
  | "coverSheet"
  | "email"
  | "flyer"
  | "infographic"
  | "letter";

export type Language = "en" | "es";
export type Template = {
  id: string;
  name: string;
  description?: string;
  salesCycle: EnrollmentCycle[];
  audience: Audience[];
  status: TemplateStatus;
  materialType: MaterialType[];
  tags?: Tag[];
  createdAt: number;
  expirationDate?: string;
  formNumber: Multilingual<string>;
  defaultEmailSubject?: Multilingual<string>;
  createdBy: string;
  lastUpdatedBy: string;
  lastUpdatedAt: number;
  locations: StateKey[];
  files: Multilingual<TemplateFile>;
  customizable: boolean;
  statusHistory: TemplateStatus[];
  languages?: Language[];
  deliveryMethods?: DeliveryMethod[];
  metrics: number;
  printOptionId?: string;
};

export type TemplateFile = Partial<
  Pick<
    UploadFile,
    | "uid"
    | "name"
    | "fileName"
    | "url"
    | "status"
    | "thumbUrl"
    | "originFileObj"
  >
> & {
  id?: string; // This is the actual file id, whereas uid for the antd fiel upload
  pages?: Page[];
  type?: FileType;
  html?: string;
  htmlSnippetData?: string;
  thumbnail?: string;
  deliveryMethods?: DeliveryMethod[];
  spMetadata?: {
    // silicon publishing related
    entities?: {
      template: SPEntity;
      script: SPEntity;
    };
  };
};
export type DeliveryMethod = "email" | "download" | "print";
export type FileDrawerMode = "new" | "edit" | "duplicate";

export type DrawerDisabledMap = {
  settings: boolean;
  files: Multilingual<boolean>;
};

export type Field<T = any> = {
  label: string;
  key: Extract<
    FieldKey,
    | "name"
    | "description"
    | "audience"
    | "expirationDate"
    | "formNumber"
    | "tags"
    | "deliveryMethod"
  >;
  required: boolean;
  options?: Array<{
    key: T;
    value: T;
    label: string;
  }>;
};

export type FieldKey =
  | "name"
  | "description"
  | "client"
  | "audience"
  | "expirationDate"
  | "formNumber"
  | "type"
  | "status"
  | "tags"
  | "thumbnail"
  | "deliveryMethod"
  | "salesCycle";

export type FieldOption = {
  label: string;
  value: FieldOptionValue;
  key: string;
};
export type FieldOptionValue = any;

export type Audience =
  | "benefitDecisionMaker"
  | "broker"
  | "businessDecisionMaker"
  | "consumer"
  | "employee"
  | "employer"
  | "producer"
  | "publicSector";

export type TemplateFilterKey = keyof Template;

export const TemplateKeyToLabel: Record<keyof Template, string> = {
  id: "ID",
  name: "Name",
  description: "Description",
  audience: "Audience",
  status: "Status",
  tags: "Tags",
  expirationDate: "Expiration Date",
  formNumber: "Form Number",
  defaultEmailSubject: "Default Email Subject",
  createdAt: "Created",
  createdBy: "Created by",
  lastUpdatedBy: "Last edited by",
  lastUpdatedAt: "Last Updated At",
  languages: "Language",
  files: "File",
  salesCycle: "Sales Cycle",
  materialType: "Material Type",
  locations: "States",
  customizable: "Customizable",
  statusHistory: "Status History",
  deliveryMethods: "Delivery Methods",
  metrics: "Metrics",
  printOptionId: "Print Option",
};

export type Page = {
  id: string; // possibly spread id for IDML
  layers?: Layer[];
};

interface LayerBase {
  id: string;
  name: string;
  variable?: Variable;
  smartVariable?: SmartVariable;
  orgLayer?: LayerBase;
}

export type Option = {
  id: string;
  value: string;
};

export interface ConditionalGroupLayer extends LayerBase {
  type: "group";
  orgLayer: TextLayer;
  smartVariable: "conditional";
  conditionalSet: ConditionalSet;
}
export interface OptionGroupLayer extends LayerBase {
  type: "group";
  orgLayer: TextLayer;
  smartVariable: "options";
  options: Option[];
}

export interface AgentGroupLayer extends OtherGroupLayer {
  subLayers: TextLayer[];
  smartVariable: "agent";
}

export interface AccountGroupLayer extends LayerBase {
  smartVariable: "account";
  type: "group";
  orgLayer: TextLayer;
  groupable?: boolean; // indicating if sub-layers can be grouped/un-grouped
}

export interface ManualGroupLayer extends OtherGroupLayer {
  smartVariable: "manual";
}

export interface ProductGroupLayer extends OtherGroupLayer {
  smartVariable: "product";
  productTypes: string[];
}

export interface ProductGroupLayer extends OtherGroupLayer {
  smartVariable: "product";
}

export interface DisclosureGroupLayer extends OtherGroupLayer {
  smartVariable: "disclosure";
}

export interface ExpirationDateGroupLayer extends OtherGroupLayer {
  smartVariable: "expirationDate";
}

export interface FormNumberGroupLayer extends OtherGroupLayer {
  smartVariable: "formNumber";
}

export interface OtherGroupLayer extends LayerBase {
  type: "group";
  subLayers: (
    | TextLayer
    | ImageLayer
    | OtherGroupLayer
    | ConditionalGroupLayer
  )[];
  orgLayer: TextLayer;
  smartVariable?: SmartVariable;
  groupable?: boolean; // indicating if sub-layers can be grouped/un-grouped
  source: "snippet" | "manual"; // indicating what the group is generated from
  maxItems?: number;
}

export type GroupLayer =
  | ConditionalGroupLayer
  | OptionGroupLayer
  | OtherGroupLayer
  | AgentGroupLayer
  | AccountGroupLayer
  | ProductGroupLayer
  | ManualGroupLayer
  | ExpirationDateGroupLayer
  | FormNumberGroupLayer;

export interface ImageLayer extends LayerBase {
  type: "image";
  imageSrc?: string;
}

export interface TextLayer extends LayerBase {
  type: "text";
}

export type LayerValue = SmartVariable | string;
export type LayerType = "image" | "text" | "group";
export type Layer = ImageLayer | TextLayer | GroupLayer;

export type SmartVariable =
  | "disclosure"
  | "product"
  | "account"
  | "conditional"
  | "options"
  | "agent"
  | "expirationDate"
  | "formNumber"
  | "manual";
export const SmartVariableOptions: Record<SmartVariable, string> = {
  disclosure: "Disclosure",
  product: "Product",
  account: "Account",
  conditional: "Conditional",
  options: "Options",
  agent: "Agent",
  expirationDate: "Expiration Date",
  formNumber: "Form Number",
  manual: "Manual",
};

export const OptionalSmartVariables: SmartVariable[] = [
  "expirationDate",
  "formNumber",
];

type ConditionOption =
  | "state"
  | "product"
  | "specificProduct"
  | "productType"
  | "location"
  | "language";

export type Op = "is" | "contains";

export type Condition = {
  id: string;
  if?: ConditionOption;
  op?: Op;
  value?: string;
};

export type ConditionalField = {
  id: string;
  conditions: Condition[];
  then?: Multilingual<string>;
};

export type ConditionalSet = {
  fields: ConditionalField[];
  else?: Multilingual<string>;
  runAllConditions?: boolean;
};

export type Variable = {
  raw: string;
  name: string;
  type: "text" | "image";
};

type SPJob = {
  success: number;
  status: "waiting" | "finished";
  id: number;
  uid: string;
};
export type SPEntity = {
  id: number;
  // more
};
export type ParsedIDML = {
  pages: Page[];
  job: SPJob;
  template: SPEntity;
  script: SPEntity;
  thumbnailUrl: string;
};

export type ParsedHTML = {
  html: string;
  pages: Page[];
  thumbnailUrl: string;
};

export enum TemplateTagsEnum {
  PROSPECTING = "Prospecting",
  ENROLLMENT = "Enrollment",
  PRODUCT = "Product",
  SERVICING = "Servicing",
  BROKER_FACING = "Broker-Facing",
  RECRUITMENT = "Recruitment",
  NEW_MATERIALS = "New Materials",
  FREQUENTLY_USED = "Frequently Used",
}

export type TemplateTagValue =
  (typeof TemplateTagsEnum)[keyof typeof TemplateTagsEnum];

export type ProductDetails = {
  name: string;
  description: string;
};

export type Multilingual<T> = Partial<Record<Language, T>>;

export type Product = {
  id: string;
  productType: string;
  primaryLanguage: Language;
  details: Multilingual<ProductDetails>;
};

export type ProductRequest = Omit<Product, "id">;

export type ProductTable = {
  id: string;
  name: string;
  productType: string;
  description: string;
  language: Language[];
};

export type ProductForm = {
  id?: string;
  primaryLanguage: Language;
  languages?: Language[];
  productType?: string;
  details: Multilingual<ProductDetails>;
};

export type ProductFieldKey = keyof Omit<Product, "id">;

export type NormalizedDisclosure = Omit<ExtendedDisclosure, "products"> & {
  products: { id: string; name: string }[];
};

export type DisclosureInfo = {
  id: string;
  name: string;
  availableLanguages: Language[];
  primaryLanguage: Language;
};

export type ExtendedDisclosure = {
  products: string[];
  locations: string[];
  productTypes: string[];
  availableLanguages: string[];
  disclosures: string[];
} & Disclosure;

export type DisclosureField = {
  id: string;
  conditions: Condition[];
  then?: Multilingual<string>;
};

export type DisclosureFilter = {
  key?: string;
  locations: string[];
  productsIds: string[];
  productsTypes: string[];
};

export type DisclosureFilterRequest = {
  locations: string;
  productsIds: string;
  productsTypes: string;
};

export type Disclosure = {
  id: string;
  name: string;
  fields: DisclosureField[];
  runAllConditions?: boolean;
  availableLanguages: Language[];
  primaryLanguage: Language;
};

export type DisclosureRequest = Omit<Disclosure, "id">;

export type NormalizedDisclosureKey = keyof Omit<
  NormalizedDisclosure,
  "id" | "runAllConditions"
>;

export type Employee = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  notes: string;
};

export type AccountLogo = UploadFile<IFile> & {
  dimensions?: { width: number; height: number };
};
export type Account = {
  id: string;
  name: string;
  userId?: string;
  url?: string;
  logos: AccountLogo[];
  employees: Employee[];
  lastUpdatedAt?: number;
};

export type AccountDrawerState = "CREATE_ACCOUNT" | "EDIT_ACCOUNT" | "NONE";

export type AgentFeedInfo = {
  subUUID: string;
  licensedStates?: string;
  territory?: string;
  email: string;
};

export type SPVariables = { assets: Record<string, string> }; // This follows the requirements from SP.

export type RenderIdmlProps = {
  sp: TemplateFile["spMetadata"];
  data: Record<string, any>;
  variables?: SPVariables;
};
