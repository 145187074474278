import { useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import {
  fetchFeedTblRow,
  PAGE_SIZE,
} from "shared/hooks/assetExporter/useFeedConfigFetch";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";
import { errorNotification } from "shared/components/customNotification/Notification";

export const usePortalFeedData = (currentAssetIndex: number) => {
  const { feedId, selectedRows, selectedAssetBatches } = useFeedContext();
  const queryClient = useQueryClient();

  const currentPage = Math.floor(currentAssetIndex / Number(PAGE_SIZE)) + 1;

  const fetchPage = useCallback(
    async (page: number) => {
      try {
        if (!feedId) {
          throw new Error("Feed ID is not available");
        }

        const feedRows = await fetchFeedTblRow({
          feedId,
          page: String(page),
          filterArgs: [
            {
              column: COLUMN_ASSET_BATCHES,
              selection: selectedAssetBatches,
            },
          ],
        });

        const selectedFeedRows = feedRows?.rows.filter(row =>
          selectedRows.includes(row.rowIdentifier),
        );

        return {
          ...feedRows,
          rows: selectedFeedRows,
        };
      } catch (error) {
        errorNotification({
          messageLabel: `An error occurred while fetching feed data`,
        });
        throw error;
      }
    },
    [feedId, selectedAssetBatches, selectedRows],
  );

  const result = useQuery(
    ["portalFeedData", feedId, currentPage, selectedAssetBatches],
    () => fetchPage(currentPage),
    {
      enabled: !!feedId,
    },
  );

  const prefetch = () => {
    const isCurrentPageLastPage =
      result?.data?.filteredCount &&
      currentPage * PAGE_SIZE >= result?.data?.filteredCount;

    if (isCurrentPageLastPage) return;
    queryClient.prefetchQuery(
      ["portalFeedData", feedId, currentPage + 1, selectedAssetBatches],
      () => fetchPage(currentPage + 1),
    );
  };

  return { result, prefetch };
};
