import { Alert, Card, Typography } from "antd";
import { PrintOptionsRangeQuantity } from "shared/types/printOptions";
import styles from "./PrintOptionProductionTimeCard.module.scss";

export const PrintOptionProductionTimeCard = ({
  productionTime,
}: {
  productionTime: PrintOptionsRangeQuantity;
}) => {
  const time =
    productionTime.min === productionTime.max
      ? productionTime.min + " day"
      : `${productionTime.min}-${productionTime.max} days`;
  return (
    <Card className={styles.card}>
      <Typography.Text>Est. Production time: {time}</Typography.Text>
      <Alert
        className={styles.alert}
        showIcon
        message="Production time is estimated. Delivery time is not included."
        type="info"
      />
    </Card>
  );
};
