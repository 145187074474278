import { Rule } from "antd/lib/form";
import { ReactNode } from "react";
import { IAd } from "./adLibrary";

import { TRuleCondition } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { TValueMappings } from "screens/assetExporter/feedConfigurationV2/shared/types";
import { IApiResponse } from "./shared";
import {
  AdInfoUpdates,
  AdType,
  ExportDestination,
  RecommendedColumnMatches,
} from "screens/assetExporter/feedConfigurationV2/exportDrawer/shared/types";

export type FeedUploadType = "custom" | "server" | "googlesheet";
export type FeedDrawerMode = "CREATE" | "UPDATE";
export type ServerFeedFreq = "Never" | "Hourly" | "Daily" | "Weekly";
type ServerFeedHourlyRepeat =
  | "Every hour"
  | "Every 2 hours"
  | "Every 3 hours"
  | "Every 4 hours"
  | "Every 6 hours"
  | "Every 8 hours"
  | "Every 12 hours";
export type ServerFeedWeeklyRepeat =
  | "Every Monday"
  | "Every Tuesday"
  | "Every Wednesday"
  | "Every Thursday"
  | "Every Friday"
  | "Every Saturday"
  | "Every Sunday";
export type ServerFeedRepeatOptions =
  | undefined
  | "--"
  | ServerFeedHourlyRepeat
  | ServerFeedWeeklyRepeat
  | IWeek;
export type ServerFeedStartTime = string | undefined;
export type ServerFeedTZ = "UTC" | "EST" | "PST";
export type ServerHoursMinutesConfig = {
  hours: number;
  minutes: number;
};
export type FeedPrevData = Record<string, any>;
export type FeedColData = {
  matchedTo?: string;
  isMatched: boolean;
  matchedFrom: string;
};
export type RecColumn = {
  name: string;
  toMatch: string[];
  required: boolean;
  requiredMatching: string[];
};
export type FeedType =
  | "facebookAds"
  | "facebookProductCatalog"
  | "facebookAutoCatalog"
  | "tiktokProductCatalog"
  | "snapchatProductCatalog"
  | "pinterestProductCatalog"
  | "googleMerchantCenter"
  | "custom"
  | "assetBuilder"
  | "pmax";

export type SetSelectedType = {
  type: FeedType;
  columnsHeaders: string[];
};

export type ExportForOption =
  | "Ad Library"
  | "CSV download"
  | "FTP"
  | "Off Platform Portal"
  | "Portal";
export type SocialFeedType = {
  name: string;
  type: FeedType;
  recommendedColumns: RecColumn[];
  downloadTemplateUrl: string;
  redirect?: boolean;
};

export type GetCsvUrlArgs = {
  feedId: string;
  createdAt?: number;
};
// follows array index logic
export enum STEP {
  ONE = 0,
  TWO = 1,
  THREE = 2,
  FOUR = 3,
  FIVE = 4,
}

export enum STEP_CREATE_BATCH {
  ONE_CONDITION = 0,
  TWO_MEDIA = 1,
  THREE_TEXT = 2,
}

export enum STEP_CREATE_BATCH_V2 {
  ONE_CONDITION = 0,
  TWO_VARIABLES = 1,
  THREE_RENAME = 2,
}

export const CREATE_BATCH_TITLES = ["Apply rules", "Media", "Text"];

export const CREATE_BATCH_TITLES_V2 = ["Apply rules", "Variables"];

export const CREATE_BATCH_TITLES_V3 = ["Conditions", "Build", "Rename"];

export type StepStatus = "wait" | "process" | "finish" | "error" | undefined;

export enum COL_SELECTION {
  EXPORT_SELECTED = "exportSelectedColumns",
  EXPORT_ALL = "exportAllColumns",
}

export enum ASSET_BATCH_SELECTION {
  EXPORT_SOME = "exportSome",
  EXPORT_ALL = "exportAll",
}

export enum ROW_SELECTION {
  EXPORT_SELECTED = "exportSelectedRows",
  EXPORT_ALL = "exportAllRows",
}

export type RadioBtnColVal = "exportSelectedColumns" | "exportAllColumns";
export type RadioBtnAssetBatchesVal = "exportSome" | "exportAll";
export type RadioBtnRowVal = "exportSelectedRows" | "exportAllRows";

export const QUERY_KEY_ASSET_BATCHES = "assetBatchesByFeed";
export const QUERY_KEY_FEED_LIST = "feedConfig";

export type PopupState = {
  record?: TAssetBatch;
  visible: boolean;
  x?: number;
  y?: number;
};

export type GetValidateCredsBody = {
  host: string;
  path: string;
  port: number;
  username: string;
  password: string;
};

type FeedDrawer = {
  googleSheetId: string;
  googleSheetName: string;
  googleSheetUrl: string;
  drawerMode: FeedDrawerMode;
  isCredValidated?: boolean;
  uploadedFile?: string;
  isNextBtnDisabled: boolean;
  uploadType: FeedUploadType;
  selectedType?: FeedType;
  columnData: FeedColData[];
  recommendedColumns: RecColumn[];
  step: number;
  latestStep: STEP;
  feedWithRule?: FeedWithRule;
  selectedSmartColIdx: number;
  selectedSmartCols: SmartCol[];
  errMsg: string;
  isEmptySmartColWarning: boolean;
  isProcessingPreview: boolean;
  reviewGeneralErrors: {
    cta: string;
    adFormat: string;
    duplicateAssetGroups: string;
  };
  reviewGeneralWarnings: string[];
  columnSortedByWarning?: boolean;
} & IFeedForm &
  AssetBuilderFeedAttrs;

export type SourceFeedRightClickPopup = {
  visible: boolean;
  displayX: number;
  displayY: number;
};

type SourceFeed = {
  isVersionsDrawerOpen: boolean;
  isFeedDrawerOpen: boolean;
  isDeleteModalOpen: boolean;
  feedDrawer: FeedDrawer;
  showSelected: boolean;
  selectedFeedIds: string[];
  search: string;
  rightClickPopup: SourceFeedRightClickPopup;
};

export type ExportFtpData = {
  url: string;
  filePath: string;
  port: number;
  username: string;
  password: string;
};
type Configure = {
  isExportDrawerOpen: boolean;
  feedColumns: string[];
  colsToExport: string[];
  isExportAllCols: boolean;
  feedType: FeedType;
  exportFor: ExportForOption[];
  areFtpCredsValid: boolean;
  isExportInProgress: boolean;
  isExportByUser: boolean;
  selectedRows: string[];
  ftpData: ExportFtpData;
  isExportAdLibOpen: boolean;
};

type AssetBatches = {
  exportExecutionArns: string[];
  exportFeedName: string | null | undefined;
  exportAssetBatchNames: string[];
  exportRowCount: number | null;
  exportClientSlug: string | null | undefined;
};

export type AssetExporterState = {
  sourceFeed: SourceFeed;
  configure: Configure;
  assetBatches: AssetBatches;
};

export type ServerConfigParams = FeedCron & {
  permToEncrypt: boolean;
  createdAt: number;
};

export type PutServerConfig = {
  success: boolean;
};

export type GetPrcdFeedForAdLib = {
  feedId: string;
  createdBy: string;
  id?: string;
};
export type PostCreateFeedCron = {
  feedFileName: string;
  feedForm: IFeedForm;
  columnData: FeedColData[];
  smartCols: SmartCol[];
  socialFeedType: string;
  isUpdate: boolean;
  feedType: FeedUploadType;
};

export type FeedConfig = {
  colData: FeedColData[];
  smartCols: SmartCol[];
};

type FtpAttrs = "url" | "filePath" | "port" | "username" | "password";
export type FtpInputAttr = {
  label: string;
  value: FtpAttrs;
  placeholder: string;
  rules: Rule[];
};

export type AdEngineGoogleSheetForm = {
  googleSheetUrl: string;
  feedName: string;
  googleSheetName: string;
  googleSheetId: string;
};

type ProcessStatusMessage =
  | "Processing Data"
  | "Generating Images"
  | "Generating Videos"
  | "Export to CSV"
  | "Export to Ftp"
  | "Completed"
  | "Failed"
  | "Waiting for a Process to Finish";

export type FeedStatus = {
  message: ProcessStatusMessage;
  percentage: number;
};

export type PrcdFeedForAdLib = {
  rows: IAd[];
  lastEvaluatedKey?: string;
};

export type FeedWithRule = {
  feedId: string;
  feedName: string;
  smartCols: SmartCol[];
};

export type PostProcessPreview = {
  rows: FeedPrevData[];
  smartColumns: SmartCol[];
};

export type ProcessedPreview = Record<string, any>[];
export type ProcessFeedBody = {
  feedId: string;
  columnsToExport: string[];
  createdBy: string;
  imageInputColumn: string;
  imageOutputColumn: string;
  videoInputColumn: string;
  videoEndInputColumn: string;
  videoOutputColumn: string;
  videoFrameStart: string;
  videoFrameEnd: string;
  videoFrameRate: string;
  selectedRows: string[];
  // ftp
  filePath: string;
  host: string;
  username: string;
  password: string;
  port: number;
};

export interface MappingDataForExportPortal {
  tags?: string[];
  metas?: string[];
  notInclude?: string[];
}

export interface PortalData {
  clientId: string;
  feedColumns: string[];
  brandId?: string;
  brandColumn?: string;
  accountId?: string;
  accountColumn?: string;
  brands?: { name: string; id: string }[];
  accounts?: { name: string; id: string }[];
}

export type ExportBatchAssetsBody = {
  feedId: string;
  createdBy: string;
  selectedRows: string[];
  assetBatchIds: string[];
  exportType: ExportDestination;
  // ftp
  filePath?: string;
  host?: string;
  username?: string;
  password?: string;
  port?: number;
  // ad library
  columnMatches?: RecommendedColumnMatches;
  updates?: AdInfoUpdates;
  adType?: AdType;
  // google spreadsheet
  mappingDataForExportPortal?: MappingDataForExportPortal;
  instanceName?: string;
  // portal
  portalData?: PortalData;
};

export type ValidateFeedColumnValuesBody = {
  feedId: string;
  columnName: string;
  rowIdentifiers: string[];
  validValues: string[];
};

export type ValidatePortalExportMetadataBody = {
  feedId: string;
  columnNames: string[];
  rowIdentifiers: string[];
  createdBy: string;
  assetBatchIds: string[];
};

export type FormattedPreviewCols = { colHeader: string; isSmartCol: boolean }[];

export type GetCsvPreview = {
  data: FeedPrevData[];
  columnHeaders: string[];
  nextPage?: number;
};
// Below type must match with the type defined in data-processor for nu
export type FeedColumn =
  | "offerConcatenation"
  | "dimeOfferId"
  | "vehicleOfferId"
  | "dimeOfferType"
  | "make"
  | "year"
  | "model"
  | "startDate"
  | "endDate"
  | "language"
  | "region"
  | "marketId"
  | "marketName"
  | "offerDisclaimer"
  | "offerType"
  | "amountPrice"
  | "amountPercentage"
  | "amountQualifier"
  | "termLength"
  | "termQualifier"
  | "downpayment"
  | "downpaymentQualifier"
  | "cashback"
  | "offerDescription"
  | "url"
  | "title"
  | "imageUrls"
  | "customLabel"
  | "rowIdentifier";

export type FeedTblCol = {
  title: string | ReactNode;
  dataIndex: string;
  key: string;
};
export type FeedTblColWithRender = {
  title: string | ReactNode;
  dataIndex: string;
  key: string;
  fixed?: string;
  render?: (value: string, row: FeedRow) => JSX.Element;
};
export type FeedTblRow = Record<FeedColumn, string> & {
  assetBatches?: string[];
};
export type SmartColumn = Record<string, TRule[]>;
export type AssetBatchDrawerMode = "Create" | "Edit";

export interface FeedRow {
  offerConcatenation: string;
  dimeOfferId: string;
  vehicleOfferId: string;
  dimeOfferType: string;
  theme: string;
  make: string;
  year: string;
  model: string;
  startDate: string;
  endDate: string;
  language: string;
  region: string;
  marketId: string;
  marketName: string;
  offerDisclaimer: string;
  offerType: string;
  amountPrice: string;
  amountPercentage: string;
  amountQualifier: string;
  termLength: string;
  termQualifier: string;
  downpayment: string;
  downpaymentQualifier: string;
  cashback: string;
  offerDescription: string;
  url: string;
  title: string;
  imageUrls: string;
  customLabel: string;
  rowIdentifier: string;
  id: string;
}

type RawColumns = {
  requiredColumns: string[];
  rowColumns: string[];
};

export type TRawData = {
  rows: FeedRow[];
  totalRows: number;
  columns: RawColumns;
  orders: string[];
  feedType: FeedType;
  imageInputColumn: string;
  imageOutputColumn: string;
  videoEndInputColumn: string;
  videoInputColumn: string;
  videoOutputColumn: string;
  videoFrameStart: string;
  videoFrameEnd: string;
  videoFrameRate: string;
  rowsCount: number;
  totalFeedCount: number;
  filteredRowIdentifiers: string[];
  assetBatchIdsForFilteredRows: string[];
  filteredCount: number;
};

export type GetRawData = {
  result: TRawData;
  error?: { message: string };
};

export type GetValuesByColumn = {
  result: { values: string[] };
  error?: { message: string };
};

export type SmartCol = {
  column: string;
  feedId: string;
  rules: TRule[];
};
export type GetSmartColumns = {
  result: {
    smartColumns: SmartCol[];
  };
  error?: { message: string };
};

export type DeleteSmartColumn = {
  result: {
    column: string;
    rules: TRule[];
  };

  error?: { message: string } | null;
};

export type SaveSmartColumn = {
  result: { smartColumn: { column: string; rules: TRule[] } };
  error?: { message: string };
};

export type PullLatestFeedData = {
  result: { message: string };
  error: { message: string } | null;
};

export type ProcessPatchFeedRowField = {
  feedId: string;
  rowIdentifier: string;
};

export type ProcessFeeds = {
  result: any;
  error?: { message: string };
};

export type ExportAssetBatchesResult = {
  executionArn: string;
  startDate: string;
};

export type ExportBatchAssets = {
  result?: ExportAssetBatchesResult;
  error?: { message: string };
};

export type ValidateFeedColumnValues = {
  result: boolean;
  error?: { message: string };
};

export type ValidatePortalExportMetadata = {
  result: boolean;
  error?: { message: string };
};

export type ExportAssetBatchesStatusOutput = {
  type: ExportDestination;
  status: string;
  url?: string;
  fileUrl?: string;
  portalUrl?: string;
};

export type ExporBatchAssetStatusResponseJson = {
  percentage: number;
  outputs: ExportAssetBatchesStatusOutput[];
};

export type ExportAssetBatchesStatus = {
  executionId: string;
  status: string;
  description: string;
  responseJson: ExporBatchAssetStatusResponseJson;
};

export type GetExportAssetBatchesStatus = {
  result?: ExportAssetBatchesStatus;
  error?: { message: string };
};

export type PatchFeedRowResult = {
  result: string;
  record: Record<string, any>;
  error?: { message: string };
};

export type RecordStatus =
  | "RUNNING"
  | "SUCCEEDED"
  | "FAILED"
  | "TIMED_OUT"
  | "ABORTED";

export type ProcessedRecord = {
  createdAt: number;
  createdBy: string;
  feedId: string;
  id: string;
  jsonFileUrl: string;
  status: RecordStatus;
  originalFeed: {
    filename: string;
    s3Bucket: string;
    s3Key: string;
    url: string;
  };
  processedRowsCount: number;
};
export type GetPrcdRecords = {
  result: {
    records: ProcessedRecord[];
  };
  error?: { message: string };
};

export type GetCsvUrl = {
  result: {
    url: string;
  };
  error?: { message: string };
};

export type DelSmartCol = {
  result: { smartColumns: [{ column: string; rules: TRule[] }] };
  error?: { message: string };
};

export enum IFrequency {
  Never = "Never",
  Hourly = "Hourly",
  Daily = "Daily",
  Weekly = "Weekly",
}

export enum IWeek {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export const HourlyRepeatOptions: ServerFeedHourlyRepeat[] = [
  "Every hour",
  "Every 2 hours",
  "Every 3 hours",
  "Every 4 hours",
  "Every 6 hours",
  "Every 8 hours",
  "Every 12 hours",
];

export const WeeklyRepeatOptions: ServerFeedWeeklyRepeat[] = [
  "Every Monday",
  "Every Tuesday",
  "Every Wednesday",
  "Every Thursday",
  "Every Friday",
  "Every Saturday",
  "Every Sunday",
];

export enum ITimeZone {
  UTC = "UTC",
  EST = "EST",
  PST = "PST",
}
export interface IFeedForm {
  feedId: string;
  frequency?: ServerFeedFreq;
  repeat?: ServerFeedRepeatOptions;
  startTime?: ServerFeedStartTime;
  timezone?: ServerFeedTZ;
  feedName: string;
  host?: string;
  username?: string;
  password?: string;
  tempPassword?: string;
  path?: string;
  port?: string;
  mode?: FeedDrawerMode;
  filterColHeader: string;
  oemTags: string[];
  storeTags: string[];
  locationTags: string[];
}

export type FeedConfigState = {
  columns: RawColumns | null;
  columnsToExport: string[];
  feedRows: FeedRow[];
  imageInput: string;
  imageOutput: string;
  orderedColumns: string[];
  videoInput: string;
  videoEndInput: string;
  videoOutput: string;
  videoFrameStart: string;
  videoFrameEnd: string;
  videoFrameRate: string;
};

export type MediaInput =
  | "imageInput"
  | "imageOutput"
  | "videoInput"
  | "videoEndInput"
  | "videoOutput"
  | "videoFrameStart"
  | "videoFrameEnd"
  | "videoFrameRate";

type AssetBuilderFeedAttrs = {
  filterColHeader: string;
  oemTags: string[];
  storeTags: string[];
  locationTags: string[];
};
export type FeedCron = {
  feedName: string;
  filterColHeader: string;
  id: string;
  lastUpdate: number;
  createdAt: number;
  lastEdited: number;
  latestExecId?: string;
  password?: string;
  feedUploadType?: FeedUploadType;
  processedStatus?: RecordStatus;
  type?: FeedType;
  version?: number;
  nextUpdate?: number;
  sync?: true;
  frequency?: ServerFeedFreq;
  host?: string;
  path?: string;
  port?: string;
  repeat?: ServerFeedRepeatOptions;
  selectedOrders?: string[];
  startTime?: ServerFeedStartTime;
  status?: "119/120";
  username?: string;
  googleSheetId?: string;
  googleSheetName?: string;
} & AssetBuilderFeedAttrs;

export type FeedTableAttrs = {
  key: string;
  feedName: string;
  versions: number;
  lastUpdate: number | "Not supported";
  frequency: ServerFeedFreq | "Not supported";
  repeat?: string;
  nextUpdate?: number | "Not supported";
  feedId: string;
  currStatus: RecordStatus;
  createdAt?: number;
  lastEdited?: number;
  type?: FeedType;
} & AssetBuilderFeedAttrs;

export type GetFeedCron = IApiResponse<{ feedCrons: FeedCron[] }>;

export type GoogleSheetsData = {
  sheetTitles: string[];
  docTitle: string;
};
export type GetGoogleSheets = IApiResponse<GoogleSheetsData>;

export type ValidateCreds = IApiResponse<{
  validateCredentials: boolean;
  hasDuplicateHeader: boolean;
}>;

export type DeleteCronFeed = IApiResponse<{ deletedFeedId: string }>;

export type PostSignedCsvUrl = IApiResponse<{ url: string }>;

export type PutCsvFile = IApiResponse<{ status: string }>;

export type PostGoogleSheetBody = {
  sheetId: string;
  sheetName: string;
  feedId: string;
  createdAt: number;
};

export type PostGoogleSheet = IApiResponse<{ success: boolean }>;

export enum ComparisonOperator {
  Equals = "Equals",
  NotEquals = "NotEquals",
  Contains = "Contains",
  DoesNotContain = "DoesNotContain",
}

export type TThenType = "template" | "text" | "regex";

export type TRule = {
  name: string;
  conditions: TCondition[];
  then: {
    type: TThenType;
    text: string;
    value: string;
    isRegexOn?: boolean; // undefined meaning regex is off
    regexTargetColumn?: {
      text: string;
      value: string;
    };
  };
};

type TCondition = {
  type: "if" | "and-if";
  leftEq: {
    text: string;
    value: string;
  };
  op: ComparisonOperator;
  rightEq: string;
};

export type TSortArgs = {
  column: string;
  direction: "asc" | "desc";
  type?: "string" | "number";
};

export type TConditionType =
  | "Equals"
  | "NotEquals"
  | "Contains"
  | "DoesNotContain"
  | "Any";
interface IValuePair {
  text: string;
  value: string;
}
export interface ICondition {
  type: "if" | "and-if" | "or-if";
  leftEq: IValuePair;
  op: TConditionType;
  rightEq: string;
}

export interface IMedia {
  column?: string;
  src?: string;
}

export enum CompositionType {
  Media = "Media",
  Template = "Template",
}

type TBaseComposition = {
  compositionId: string;
  feedId: string;
  template: string;
  variables: TValueMappings;
  assetBatchId?: string;
  duration: number;
  temporalRemoved?: boolean;
};

export enum MediaSubtype {
  Feed = "Feed",
  CAM = "CAM",
}

export type TMediaComposition = TBaseComposition & {
  type: CompositionType.Media;
  url: string;
  subtype: MediaSubtype;
  column?: string;
  name?: string;
  audioEnabled?: boolean;
};

export type TTemplateComposition = TBaseComposition & {
  type: CompositionType.Template;
  template: string;
  variables: TValueMappings;
  audioSelVariableId: string[]; //selected audio variable ids
};

export type TComposition = TTemplateComposition | TMediaComposition;

export type TAssetBatch = {
  feedId: string;
  assetBatchId?: string;
  name: string;
  conditions: ICondition[];
  compositions: TComposition[] | TTemplateComposition[];
  createdAt: number;
  variables?: TValueMappings;
  template?: string;
  media: IMedia[];
  artboardName?: string;
  namingRulePattern?: string;
  audioFiles?: TAudioData[];
};

export type TAssetBatchRequest = Omit<TAssetBatch, "createdAt">;

export type TAssetBatchId = Required<
  Pick<TAssetBatch, "feedId" | "assetBatchId">
> & { undo?: boolean; message?: string };

export type MediaColumn = {
  columnName: string;
  count: number;
};

export type GetMediaColumnsQueryParams = {
  rules: TRuleCondition[];
};

export type MediaColErrorArgs = {
  backgroundColumn?: string;
  validRowsForCol: number | undefined;
  totalRows: number;
};

const MediaInputErrors = {
  EMPTY: "EMPTY",
  INVALID: "INVALID",
  COLUMN_DISPARITY: "COLUMN_DISPARITY",
};
export type MediaInputError = keyof typeof MediaInputErrors | undefined;

export type TVideoAudio = {
  variableIds: string[];
  compositionId: string;
  canvas: fabric.Canvas | undefined;
};

export type TAudioData = {
  type: MediaSubtype;
  id: string;
  name: string;
  src: string;
  start: number; // in milliseconds
  end: number; // in milliseconds
  column?: string;
};

export type TAudioFile = TAudioData & {
  file?: HTMLAudioElement;
};

export type THasAudioBody = {
  videoSrc: string;
};

export type THasAudioResponse = {
  hasAudioVideo: boolean;
};

export type GoogleDriveAPIUrlResponse = {
  url: string;
  accessToken: string;
};

export type GoogleDriveUrlMode = "metadata" | "file";

export type GoogleDriveMetadataResponse = {
  kind: string;
  id: string;
  name: string;
  mimeType: string;
  teamDriveId: string;
  driveId: string;
};

export enum CallToAction {
  LEARN_MORE = "Learn More",
  SHOP_NOW = "Shop Now",
  CONTACT_US = "Contact Us",
  GET_OFFER = "Get Offer",
  GET_QUOTE = "Get Quote",
  BOOK_NOW = "Book Now",
}
